import React, { Component } from 'react'
import { navigate } from '@reach/router'
import Button from '@material-ui/core/Button'
import iconSet from '../../site_icons/selection.json'
import IcomoonReact, { iconList } from 'icomoon-react'

class BackButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: '',
            backToText: '',
            previousPage: '',
        }
    }

    componentDidMount() {

        //! This is not actively being used. Check Nav.js for back button

        const locale = this.props.locale

        const localeArray = locale.split('-')
        const language = localeArray[0]
        const region = localeArray[1]

        let backToText;
        let previousPage;

        if (language === 'en') {
            backToText = 'Back to';
            previousPage = this.props.previousPage;
        } else if (language === 'fr') {
            backToText = 'Retour à la';
            switch (this.props.previousPage) {
                case 'Main Screen':
                    previousPage = 'Page d’accueil';
                    break;
                case 'Categories':
                    previousPage = 'Catégories';
                    break;
                case 'Subcategories':
                    previousPage = 'Sous-catégories';
                    break;
                case 'Products':
                    previousPage = 'Produits';
                    break;
                default:
                    break;
            }
        } else if (language === 'es') {
            backToText = '';
            switch (this.props.previousPage) {
                case 'Main Screen':
                    previousPage = 'Menu Principal';
                    break;
                case 'Categories':
                    previousPage = 'Categorias';
                    break;
                case 'Subcategories':
                    previousPage = 'Subcategorias';
                    break;
                case 'Products':
                    previousPage = 'Producto';
                    break;
                default:
                    break;
            }
        }

        this.setState({
            lang: language,
            backToText: backToText,
            previousPage: previousPage
        });
    }

    // componentWillReceiveProps(nextProps, nextState) {
    //     let lang = this.props.languageRegionReducer.language
    //     this.setState({ lang: lang });

    //     let backToText;
    //     let previousPage;
    //     if (lang === 'en') {
    //         backToText = 'Back to';
    //         previousPage = this.props.previousPage;
    //     } else if (lang === 'fr') {
    //         backToText = 'Retour à la';
    //         switch (this.props.previousPage) {
    //             case 'Main Screen':
    //                 previousPage = 'Page d’accueil';
    //                 break;
    //             case 'Categories':
    //                 previousPage = 'Catégories';
    //                 break;
    //             case 'Subcategories':
    //                 previousPage = 'Sous-catégories';
    //                 break;
    //             case 'Products':
    //                 previousPage = 'Produits';
    //                 break;
    //             default:
    //                 break;
    //         }
    //     } else if (lang === 'es') {
    //         backToText = '';
    //         switch (this.props.previousPage) {
    //             case 'Main Screen':
    //                 previousPage = 'Menu Principal';
    //                 break;
    //             case 'Categories':
    //                 previousPage = 'Categorias';
    //                 break;
    //             case 'Subcategories':
    //                 previousPage = 'Subcategorias';
    //                 break;
    //             case 'Products':
    //                 previousPage = 'Producto';
    //                 break;
    //             default:
    //                 break;
    //         }
    //     }

    //     this.setState({
    //         lang: lang,
    //         backToText: backToText,
    //         previousPage: previousPage
    //     });

    // }

    handleClick = () => {
        navigate(this.props.path)
    }

    render() {
        return (
            <Button variant="contained" color="primary" className="button col-12" onClick={this.handleClick}>
                <span className="left"><IcomoonReact iconSet={iconSet} color="#fff" size={15} icon="arrow-left2" /></span>&nbsp;{this.state.backToText} {this.state.previousPage}
            </Button>
        );
    }
}

export default BackButton;


