import React, { Component } from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import HCaptcha from '@hcaptcha/react-hcaptcha';
import axios, { isCancel, AxiosError } from 'axios';

import BackButton from "../components/BackButton"
import SiteContext from "../context/SiteContext"
import FixedMenu from "../components/FixedMenu"
import SEO from "../components/seo"

class Contact extends Component {
  static contextType = SiteContext

  constructor(props) {
    super(props)
    this.state = {
      pardotUrl: "",
      thankyou: false,
      checked: false,
      token: '',
      ekey: '',
      showVerifyMessage: false,
    }
  }

  componentDidMount() {

  }

  handleCheckbox = value => event => {
    if (event.target.checked) {
      this.setState({ checked: true })
    } else {
      this.setState({ checked: false })
    }
  }

  handleVerificationSuccess = async (token, ekey) => {
    console.log("verify")
    this.setState({ token, ekey })
  }

  handleSubmit = async e => {
    e.preventDefault()
    console.log("submit")

    if (!this.state.token.length || !this.state.ekey.length) {
      this.setState({ showVerifyMessage: true })
    } else {
      // build a formdata object from the form
      const form = document.getElementById("contact-form")
      const formData = new FormData(form)
      const data = {}
      formData.forEach((value, key) => {
        data[key] = value
      })
      formData.append('token', this.state.token)
      formData.append('site', 'Senergy')

      try {
        await axios({
          method: "post",
          url: "https://senergy-finestone-cms.motumdev.com/actions/motum-freeform-module/default/handle-submit",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(function (response) {
            //handle success
            console.log(response);
            window.location.href = "/thank-you"
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }

  }

  render() {
    return (
      <SiteContext.Consumer>
        {siteContext => (
          <>
            <SEO title="Contact Us" />
            <div className="wrapper app-header-style-text">
              <div className="content-wrapper">
                <div className="app-header">
                  <div className="app-header-content">
                    <h1 className="app-header__title">Contact Us</h1>
                    <div className="spacer"></div>
                    <div className="app-header__description center">
                      <p>
                        <a className={`senergy`} href="tel:888-338-7170">
                          Customer Service: 888-338-7170
                        </a>
                      </p>
                      <p>
                        <a className={`senergy`} href="tel:800-589-1336">
                          Technical Support: 800-589-1336
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="contact-form__wrapper">
                  {this.state.thankyou ? (
                    <p className={`site--senergy thankyou`}>
                      Thank you for your submission!
                    </p>
                  ) : null}
                  <form onSubmit={e => this.handleSubmit(e)} method="post" id="contact-form">
                    <div className="form-field col-12">
                      <TextField
                        label="First Name"
                        variant="outlined"
                        name="firstname"
                      />
                      <span className="spacer"></span>
                      <TextField
                        label="Last Name"
                        variant="outlined"
                        name="lastname"
                      />
                      <span className="spacer"></span>
                      <TextField label="Email" variant="outlined" name="email" />
                      <span className="spacer"></span>
                      <TextField
                        label="Comments"
                        variant="outlined"
                        multiline={true}
                        rows={4}
                        inputProps={{ maxLength: 500 }}
                        name="comments"
                      />
                      <span className="spacer"></span>
                      <div>
                        <span className="spacer"></span>
                        <p>
                          MBCC-Group is committed to protecting and respecting
                          your privacy, and we'll only use your personal
                          information to administer your account and to provide
                          the products and services you requested from us. From
                          time to time, we would like to contact you about our
                          products and services, as well as other content that may
                          be of interest to you. If you consent to us contacting
                          you for this purpose, please tick below to say how you
                          would like us to contact you:
                        </p>
                        <p className="consent-checkbox">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.checked}
                                onChange={this.handleCheckbox("agree")}
                                value={"agree"}
                                required={true}
                              />
                            }
                            label="I agree to receive other communications from MBCC-Group."
                          />
                        </p>
                        <p>
                          You can unsubscribe from these communications at any
                          time. For more information on how to unsubscribe, our
                          privacy practices, and how we are committed to
                          protecting and respecting your privacy, please review
                          our Privacy Policy.
                        </p>
                        <p>
                          By clicking submit below, you consent to allow
                          MBCC-Group to store and process the personal information
                          submitted above to provide you the content requested.
                        </p>
                      </div>
                      <span className="spacer"></span>
                      <HCaptcha
                        sitekey="643ec282-da0c-4d76-a2c6-9e34c0b6d0b2"
                        onVerify={(token, ekey) => this.handleVerificationSuccess(token, ekey)}
                      />
                      {
                        this.state.showVerifyMessage
                          ? <p>Please verify you are not a robot.</p>
                          : null
                      }
                      <span className="spacer"></span>
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        className="button col-12"
                      >
                        Submit
                      </Button>
                      <span className="spacer"></span>
                    </div>
                  </form>
                  <p className="data-privacy-link">
                    <a
                      className={`senergy`}
                      href="https://mbcc.sika.com/en-us/footer/data-protection"
                      target="_blank"
                      rel="nofollow"
                    >
                      Data Privacy
                    </a>
                  </p>
                </div>
              </div>
              <FixedMenu site="senergy" />
            </div>
          </>
        )}
      </SiteContext.Consumer>
    )
  }
}

export default Contact
